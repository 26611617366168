#createProgramBundling .ant-checkbox-group {
    display: grid;
    max-height: 250px;
    overflow: auto;
}

#product-selected-list {
    width: 225px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 6px;
    margin-right: 6px;
    color: #fff;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 9999px;
    font-size: 12px;
    font-weight: 600;
    background-color: #c9264b;
    display: inline-block;
}