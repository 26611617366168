@import '../../variables.css';

#DataListHighlight .ant-list-items > .ant-list-item > .ant-row > .ant-col > h5.ant-typography {
  font-weight: unset;
  font-size: var(--font-size-12);
  color: var(--color-gray);
}

#DataListHighlight .ant-list-items > .ant-list-item > .ant-row > .ant-col > .row-data {
  display: flex;
  align-items: center;
}

#DataListHighlight .ant-list-items > .ant-list-item > .ant-row > .ant-col > .row-data > .icon-instructure {
  border: 1px solid var(--color-white);
  background-color: #fbf0f2;
  color: #C9264B;
  padding: 5px;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  text-align: center;
  position: relative;
  margin-right: -10px;
}

#DataListHighlight .ant-list-items > .ant-list-item > .ant-row > .ant-col > .row-data > .learners {
  border: 1px solid var(--color-white);
  background-color: #c9264b12;
  color: #C9264B;
  margin-right: 10px;
  padding: 5px 10px;
  text-align: center;
}

#DataListHighlight .ant-list .ant-list-pagination {
  display: none;
}