@import '../../variables.css';

#BaseLayout {
  min-height: 100vh;
  width: 100vw;
}

#BaseLayout .header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#BaseLayout .header-content .header-action > button {
  margin-left: 10px;
}
#BaseLayout .header-content .header-action > div {
  margin-left: 10px;
}

#BaseLayout .header-content.header-mobile {
  display: inline;
}
#BaseLayout .header-content.header-mobile .ant-typography {
  font-size: 14px;
  color: #7b7777;
}
#BaseLayout .header-content.header-mobile h1.ant-typography {
  font-size: 20px;
}
#BaseLayout .header-content.header-mobile .ant-select {
  margin: 18px 0 0 0;
  width: 100% !important;
  border-radius: 8px;
}

#ContentLayoutStudent {
  /* background-color: #f6f6f6;
  padding: 20px 30px;
  border-radius: 15px; */
  /* margin-top: 30px; */
  position: relative;
  margin-bottom: 80px;
}
