#DataTable {
  .loading-table {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
  }
  .ant-table {
    font-size: 14px;
  }

  .ant-pagination-item {
    border-radius: 25px;
    font-size: 12px;
    min-width: 25px;
    height: 25px;
    line-height: 22px;
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: 25px;
    height: 25px;
    line-height: 22px;
  }

  .ant-table-thead > tr > th {
    background: none;
    border-bottom: 4px solid #f0f0f0;
    font-weight: 100;
    color: #9e9e9e;
    // white-space: nowrap;

    &.ant-table-cell-fix-left {
      background: #fff;
    }
  }

  .ant-table-column-sorters{
    padding-top: 10px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover
  .ant-table-tbody > tr > td {
    background: #fbeaee;
  }

  .styleFree{
    .ant-table-tbody > tr > td {
      padding: unset;
    }
  }
  .action-table {
    text-align: right;
    .ant-btn {
      margin-right: 10px;
    }
  }

  .custom-row-click {
    .ant-table-row:hover {
      border-radius: 10px;
      cursor: pointer;
    }
  }
}
