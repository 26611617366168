#DetailEntity .section-header {
  padding: 30px 50px;
  background-color: var(--color-background-content);
  border-radius: 20px;
}

#DetailEntity .section-header > .action-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
#DetailEntity .section-header > .action-header > button {
  border: unset;
  box-shadow: unset;
  background-color: unset;
}
#DetailEntity .section-header > .action-header > div > button {
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
  background-color: unset;
}

#DetailEntity .section-header > .information-header {
  display: grid;
}
#DetailEntity .section-header > .information-header > .material {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
#DetailEntity .section-header > .information-header > .material .material-value {
  display: block;
  align-items: center;
  padding: 15px 15px;
  border: 1px solid var(--color-primary);
  border-radius: 15px;
}
#DetailEntity .section-header > .information-header > .material .action .ant-btn{
  height: 55px;
  border-radius: 15px;
}


#DetailEntity .content-detail {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px 30px 50px 30px;
  background-color: var(--color-background-content);
  border-radius: 20px;
}
#DetailEntity .content-detail > .action-content {
  padding: 0 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#DetailEntity .content-detail > .action-content > button {
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
  background-color: unset;
}
#DetailEntity .content-detail .material-link-assignment {
  width: 100%;
  height: 500px;
}
#DetailEntity .content-detail .material-link-assignment > iframe {
  width: 100%;
  height: 500px;
}
#DetailEntity .content-detail .finalGroupSection .ant-collapse-content-box {
  padding: 0;
}
#DetailEntity .content-detail .finalGroupSection .section-data {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 25px 10px 25px;
  
  border-bottom: 1px solid #efefef;
}
#DetailEntity .content-detail .finalGroupSection .section-data.end {
  margin-bottom: 0;
}
#DetailEntity .content-detail .finalGroupSection .section-data .info {
  display: flex;
  align-items: flex-start;
}
#DetailEntity .content-detail .finalGroupSection .section-data .info .title {
  width: 150px;
  font-weight: bold;
}
#DetailEntity .content-detail .finalGroupSection .section-data .info .member-name {
  /* display: flex; */
}
#DetailEntity .content-detail .finalGroupSection .section-data .option-status {
  display: flex;
  align-items: center;
}
#DetailEntity .content-detail .finalGroupSection .section-data .option-status .icon-file {
  background: #C9264B;
  border-radius: 100%;
  padding: 4px;
  width: 35px;
  height: 35px;
  font-size: 18px;
  color: white;
}
#DetailEntity .content-detail .finalGroupSection .section-data .option-status .status-file {
  border: 1px solid #efefef;
  padding: 5px 10px;
  border-radius: 20px;
  margin-left: 10px;
}
#DetailEntity .content-detail .finalGroupSection .section-data .option-status .status-file.success {
  border: 1px solid #389517;
  color: #389517;
}

#DetailEntity .empty-content {
  width: 40%;
  min-height: max-content;
  text-align: center;
  margin: auto;
  margin-top: 100px;
}

#DetailEntity .empty-content > .content-empty {
  margin-top: 30px;
  margin-bottom: 30px;
}


#DetailEntity .quiz-content .quiz-detail {
  margin-top: 30px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card {
  margin: 0 40px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card .ant-card-body {
  padding: 20px 40px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card .ant-card-body .items-question {
  margin-bottom: 20px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card .ant-card-body .items-question .is-question {
  margin-bottom: 10px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card .ant-card-body .items-question .is-answer {
  padding: 0 20px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card .ant-card-body .items-question .is-answer > div {
  margin-bottom: 5px;
}

#DetailEntity .quiz-content .quiz-info .quiz-header {
  display: flex;
  justify-content: space-between;
}
