#CardDataEvent .ant-card {
  margin-bottom: 15px;
  /* background-color: #e8a0b1 !important; */
}
#CardDataEvent .ant-card:hover {
  cursor: pointer;
  box-shadow: var(--box-shadow-base);
}
#CardDataEvent .ant-card .ant-card-head {
  padding: 10px;
  min-height: 20px;
  border-bottom: 1px solid #f0f0f0;
}
#CardDataEvent .ant-card .ant-card-head .ant-card-head-title {
  padding: 0;
}
#CardDataEvent .ant-card .ant-card-body {
  padding: 15px;
}

#CardDataEvent .events-entity {
  margin-left: 20px;
}

.pop-event {
  width: 400px !important;
}
