:root {
  --color-primary: #C9264B;
  --color-secondary: #ffa700;

  --color-black: #000000;
  --color-gray: #777777;
  --color-lighygray: #f0f0f0;
  --color-link: #00b1ff;
  --color-border: #e3e3e3;
  --color-white: #ffffff;

  --color-success: #008744;
  --color-warning: #ffa700;
  --color-danger: #d62d20;
  
  --color-background-content: #f9f9f9;

  --color-background-white: #ffffff;
  --color-background-lightwhite: #f4f4f4;
  --color-background-lightwhitebold: #e0e0e0;
  --color-background-gray: #777777;
  --color-background-lightgray: #bbbbbb;
  --color-background-lightgray2: #d6d6d6;
  --color-spybackground-red: #c9264b12;


  --font-size-10: 0.625rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-21: 1.3125rem;
  --font-size-22: 1.375rem;
  --font-size-24: 1.5rem;
  --font-size-25: 1.5625rem;
  --font-size-32: 2rem;

  --box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);
}