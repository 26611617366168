@import '../../../variables.css';

#MainControl {
  padding-bottom: 30px;
}
#MainControl .instructor-about {
  margin-bottom: 20px;
}
#MainControl .instructor-about .profile {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}
#MainControl .instructor-about .profile .ant-avatar {
  margin-right: 10px;
}
#MainControl .instructor-about .profile h5 {
  margin: unset;
}

#MainControl .list-course .information-content {
  text-align: left;
}
#MainControl .list-course .information-content .ant-btn {
  margin-top: 10px;
}
#MainControl .list-course .list-content {
  /* margin-top: 10px; */
  /* padding: 10px; */
  /* border-radius: 15px; */
  /* background-color: #fafafa; */
  /* scroll-behavior: smooth; */
  /* text-align: left; */
  /* position: fixed; */
  /* z-index: 1; */
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  /* height: calc(100% - 120px); */
  /* width: 300px; */
}
#MainControl .list-course .list-content .ant-btn {
  margin-bottom: 10px;
}

#MainControl .ant-tree {
  background-color: #fafafa;
}
#MainControl
  .ant-tree
  .parrent-tree
  .ant-tree-node-content-wrapper
  .ant-tree-title {
  font-size: 16px;
  font-weight: bold;
}
#MainControl .ant-tree .parrent-tree .ant-tree-switcher {
  background: #fafafa;
}
#MainControl
  .ant-tree
  .parrent-tree
  .ant-tree-switcher
  .ant-tree-switcher-icon {
  background: #fafafa;
  font-size: 16px;
}
#MainControl
  .ant-tree
  .activity-tree
  .ant-tree-node-content-wrapper
  .ant-tree-title {
  font-weight: bold;
  font-style: italic;
}
#MainControl
  .ant-tree
  .activity-tree
  .ant-tree-switcher
  .ant-tree-switcher-icon {
  background: #fafafa;
  font-size: 16px;
}
#MainControl .ant-tree .entity-single .ant-tree-switcher {
  display: none;
}
#MainControl .ant-tree .entity-single .ant-tree-switcher {
  display: none;
}

/* PWA setup */
#MainControl.mobile .list-course .list-content {
  position: relative;
  width: 100%;
  background-color: transparent;
  padding: unset;
  border-radius: unset;
}

#MainControl.mobile .list-course .list-content .collapse-mobile .ant-collapse {
  background-color: transparent;
  border: unset;
}
#MainControl.mobile
  .list-course
  .list-content
  .collapse-mobile
  .ant-collapse
  .ant-collapse-item {
  margin-bottom: 10px;
  border: unset;
  background-color: #ffffff;
  border-radius: 8px;
}
#MainControl.mobile
  .list-course
  .list-content
  .collapse-mobile
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-header-text {
  font-size: 1rem;
}
#MainControl.mobile
  .list-course
  .list-content
  .collapse-mobile
  .ant-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-header-text {
  color: #c9264b;
}
#MainControl.mobile
  .list-course
  .list-content
  .collapse-mobile
  .ant-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-expand-icon
  > span
  > svg {
  fill: #c9264b;
}
#MainControl.mobile
  .list-course
  .list-content
  .collapse-mobile
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-content {
  background-color: transparent;
}
#MainControl.mobile
  .list-course
  .list-content
  .collapse-mobile
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  padding: 15px 15px 15px 30px;
}

#MainControl.mobile .list-course .list-content .ant-tree {
  background-color: #ffffff;
  border-radius: unset;
}
#MainControl.mobile
  .list-course
  .list-content
  .ant-tree
  .activity-tree
  .ant-tree-switcher {
  display: none;
}

#MainControl .text-fade {
  opacity: 1;
  animation-name: fadeshow;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

#MainControl .ant-menu-item {
  color: #333333;
}
#MainControl .ant-menu-item:hover {
  color: var(--color-primary);
}

@keyframes fadeshow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* #MainControl .link-panel:hover {
  cursor: pointer;
  border-left: 2px solid var(--color-gray);
  margin-left: -2px;
}

#MainControl .link-panel .entity-name {
  font-size: 14px;
}

#MainControl .link-panel .entity-name:active {
  font-size: 13.5px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#MainControl .link-panel.active {
  border-left: 2px solid var(--color-primary);
  color: var(--color-primary);
  margin-left: -2px;
  font-weight: 600;
  cursor: pointer;
}

#MainControl .site-collapse-custom-collapse .site-collapse-custom-panel {
  overflow: hidden;
  border: 0px;
} */

#MainControl .ant-menu-submenu-selected .ant-menu-submenu-arrow,
#MainControl .ant-menu-submenu-selected {
  color: #c9246b !important;
}

#MainControl .ant-menu-submenu-arrow:hover {
  color: #c9246b !important;
}
