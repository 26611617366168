#ContentQuiz {
  position: relative;
}
#ContentQuiz .quiz-info {
  padding: unset;
}
#ContentQuiz .quiz-info .quiz-scoring {
  margin-top: 20px;
  border: 1px solid #dfdfdf;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #fff;
  width: 350px;
  display: flex;
}
#ContentQuiz .quiz-info .quiz-scoring > div {
  margin-right: 20px;
}
#ContentQuiz .quiz-info .card-review-quiz .ant-card-body {
  padding: 20px;
}
#ContentQuiz .quiz-info .action-button-info {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
#ContentQuiz .question-panel {
  position: relative;
}
#ContentQuiz .question-panel .timer {
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #9f1d3c;
  background-color: white;
  font-weight: bold;
  color: #9f1d3c;
}
#ContentQuiz .question-panel .timer .ant-typography {
  color: #9f1d3c;
}
#ContentQuiz .question-panel .quiz-form .list-question {
  max-height: 600px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

#ContentQuiz .question-content {
  white-space: normal;
  display: flex;
}
#ContentQuiz .question-content.mobile {
  white-space: normal;
  display: flex;
  flex-direction: column;
}
#ContentQuiz .steps-timer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
#ContentQuiz .question-content .steps-content {
  margin-bottom: 20px;
}
/* #ContentQuiz .question-content .steps-content .ant-card-question .answer-item {} */
#ContentQuiz .question-content .steps-content .ant-card-question .answer-item.essay .item-answer .textarea-essay > textarea {
  border-radius: 10px 10px 30px;
}
#ContentQuiz .question-content .steps-action {
  display: block;
}
#ContentQuiz .question-content.mobile .steps-paginations {
  width: 100% !important;
  overflow: auto;
  margin-bottom: 10px;
  padding-bottom: 20px;
}
#ContentQuiz .question-content .steps-paginations {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
#ContentQuiz .question-content .steps-paginations .number-list {
  grid-gap: 0px;
  display: grid;
  flex-basis: content;
  grid-template-columns: repeat(10,35px);
}
#ContentQuiz .question-content .steps-paginations .number-question {
  background-color: #efefef;
  color: #7b7777;
  padding: 5px;
  border-radius: 2px;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;
  cursor: pointer;
}
#ContentQuiz .question-content .steps-paginations .number-question:hover {
  border: 1px solid #333333;
}
#ContentQuiz .question-content .steps-paginations .number-question.active {
  border: 1px solid #AF4D63;
  background-color: #FFF3F6;
  color: #AF4D63;
}
#ContentQuiz .question-content .steps-paginations .number-question.isanswered {
  background-color: #FFF7CD;
}
#ContentQuiz .question-content .steps-paginations .number-question.correct {
  background-color: #B2DAB1;
  color: #fff;
}
#ContentQuiz
  .question-content
  .steps-paginations
  .number-question.active.correct {
  border: 1px solid #333333;
}
#ContentQuiz .question-content .steps-paginations .number-question.incorrect {
  background-color: #FFE8DC;
  color: #FF4242;
}
#ContentQuiz
  .question-content
  .steps-paginations
  .number-question.active.incorrect {
  border: 1px solid #333333;
}
#ContentQuiz .question-content .steps-action .prev-btn {
  float: left;
  margin-top: 10px;
}
#ContentQuiz .question-content .steps-action .next-btn {
  float: right;
  margin-top: 10px;
}
#ContentQuiz .question-content .steps-action .done-btn {
  float: right;
  margin-top: 10px;
}
#ContentQuiz .question-content .ant-pagination-prev {
  display: none;
}
#ContentQuiz .question-content .ant-pagination-next {
  display: none;
}
#ContentQuiz .question-content .ant-card-question {
  max-height: 400px;
  overflow-y: auto;
}
#ContentQuiz .question-content.mobile .contents {
  width: 100% !important;
  overflow: auto;
}
#ContentQuiz .question-content.mobile .ant-card-question {
  max-height: unset;
  overflow-y: unset;
  border-bottom: unset;
  border-left: unset;
  border-right: unset;
}
#ContentQuiz .question-content .ant-card-question .ant-card-head .ant-card-head-title {
  white-space: normal;
}
#ContentQuiz .question-content .ant-card-question .ant-card-body {
  padding: 10px 30px !important;
  min-height: 400px;
}
#ContentQuiz .question-content.mobile .ant-card-question .ant-card-body {
  padding: 10px !important;
  min-height: unset !important;
}
#ContentQuiz .question-panel .quiz-form .list-question .question-image {
  display: block;
}
#ContentQuiz
  .question-panel
  .quiz-form
  .list-question
  .question-image
  .item-img {
  width: 100%;
  margin-bottom: 10px;
}
#ContentQuiz
  .question-panel
  .quiz-form
  .list-question
  .ant-card
  .ant-card-body {
  padding: 30px;
  min-height: 400px;
}
#ContentQuiz .question-panel .quiz-form .list-question .button-question-action {
  text-align: right;
}

.ModalFinish .ant-modal-content .ant-modal-body .body-content {
  text-align: center;
}
.ModalFinish .ant-modal-content .ant-modal-body .body-content .text-content {
  width: 100%;
  margin: 30px auto 0;
  padding: 0 15px;
  text-align: center;
}
.ModalFinish
  .ant-modal-content
  .ant-modal-body
  .body-content
  .text-content
  > span {
  font-size: 14px;
}
.ModalFinish .ant-modal-content .ant-modal-body .body-content .btn-content {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 20px;
}

/* PWA setup */
#ContentQuiz.mobile .quiz-info {
  padding: unset !important;
}

#ContentQuiz.mobile .quiz-info .ant-card.card-review-quiz .ant-card-body {
  padding: 10px !important;
}
