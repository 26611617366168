@import '../../../variables.css';

#SideMenuStudent {
  background-color: var(--color-white);
  height: calc(100%);
  position: fixed;
  z-index: 1;
  border-right: 1px solid #f0f0f0;
  padding: 20px 20px 20px 0px;
  text-align: center;

}

#SideMenuStudent .ant-layout-sider-collapsed {
  padding: 20px;
}

#SideMenuStudent .ant-menu-inline {
  border: unset;
}
#SideMenuStudent .ant-menu-vertical {
  border: unset;
}

#SideMenuStudent .ant-layout-sider-trigger {
  background-color: var(--color-primary);
  height: 40px;
  line-height: 40px;
}

#SideMenuStudent .image-logo {
  cursor: pointer;
  text-align: left;
  margin: 20px 0 20px 20px;
}

#SideMenuStudent .section-menu {
  height: 90%;
  position: relative;
}

#SideMenuStudent .section-menu > .main-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 calc(50% - 16px / 2) !important;
  border: unset;
}

#SideMenuStudent .section-menu > .main-menu > .ant-menu-item {
  padding-left: 40px !important;
}

#SideMenuStudent .section-menu > .main-menu > .ant-menu-item-selected {
  background-color: unset;
}

#SideMenuStudent .section-menu > .setup-menu {
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  padding: 0 30px;
}

#SideMenuStudent .section-menu > .setup-menu .ant-menu-item {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

#SideMenuStudent .section-menu > .setup-menu.ant-menu-inline-collapsed {
  width: 40px;
}
