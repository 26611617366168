#CommingsoonPage {
  margin-top: 200px;
  height: 100%;
  .content {
    width: 80%;
    margin: auto;
    text-align: center;

    .logo-app {
      margin-bottom: 20px;
      svg {
        height: 100px;
      }
    }

    .content-text {
      font-size: 16px;
      font-style: italic;
      color: #7B7777;
    }
  }
}
