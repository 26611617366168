#VideoPlayer {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#VideoPlayer .player {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #c42455;
}

#VideoPlayer .player .playerComponent {
  position: relative;
  width: 100%;
  height: 100%;
}

#VideoPlayer .fullscreen-enabled .playerComponent .react-player-res {
  height: 100% !important;
}

#VideoPlayer
  .fullscreen-enabled
  .playerComponent
  .react-player-res.mobile {
  height: 100% !important;
}

#VideoPlayer .playerComponent .react-player-res.mobile {
  height: auto !important;
}

@media only screen and (min-width: 768px) {
  #VideoPlayer .playerComponent .react-player-res {
    height: 200px !important;
  }
}

@media only screen and (min-width: 992px) {
  #VideoPlayer .playerComponent .react-player-res {
    height: 380px !important;
  }
}

@media only screen and (min-width: 1200px) {
  #VideoPlayer .playerComponent .react-player-res {
    height: 567px !important;
  }
}

#VideoPlayer .player .ant-typography {
  font-size: 16px;
  font-weight: bold;
  color: white;
}
#VideoPlayer .player .video-progress {
  position: absolute;
  color: white;
  padding: 15px;
  right: 5px;
  background: #c42455;
  border-radius: 0 0 0 10px;
}
#VideoPlayer .player .fullscreen-enabled .video-progress {
  position: absolute;
  color: white;
  padding: 15px;
  top: 25px;
  right: 0px;
  background: #c42455;
  border-radius: 10px 0 0 10px;
}
#VideoPlayer .player .panel-control {
  padding: 10px 30px;
  background-color: black;
}
#VideoPlayer .player .panel-control .button-control-left {
  text-align: left;
}
#VideoPlayer .player .panel-control .button-control-right {
  text-align: right;
}
#VideoPlayer .player .panel-control .button-control .ant-btn {
  margin-right: 5px;
}
#VideoPlayer .player .panel-control .seek-timer {
  width: 100%;
  display: flex;
  align-items: center;
}
#VideoPlayer .player .panel-control .seek-timer .ant-btn {
  background-color: transparent;
  border-color: transparent;
}
#VideoPlayer .player .panel-control .seek-timer .seeker-input {
  width: 100%;
  margin: auto 15px;
  display: flex;
  justify-content: space-between;
}
#VideoPlayer
  .player
  .panel-control
  .seek-timer
  .seeker-input
  .ant-slider {
  width: 100%;
}
#VideoPlayer .player .panel-control .loaded-video {
  background-color: white;
  margin-left: 20px;
  border-radius: 100%;
}

#VideoPlayer .controllers {
  width: 350px;
  padding: 20px;
}

.ant-popover
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  padding: 20px 5px;
}

.ant-slider .ant-slider-track {
  background-color: var(--color-primary) !important;
}
.ant-slider .ant-slider-handle {
  border: solid 2px var(--color-primary) !important;
}
.ant-slider .ant-slider-handle:focus {
  box-shadow: 0 0 0 5px rgb(255 24 24 / 12%);
}

/* PWA setup */
#VideoPlayer .player.mobile {
  padding: unset !important;
  border-radius: unset !important;
}
#VideoPlayer .player.mobile .playerComponent {
  /* height: auto !important; */
}
#VideoPlayer .player.mobile .panel-control {
  padding: 0 20px !important;
}
