@import '../../variables.css';

#CardDataList {
  margin-bottom: 30px;
}

#CardDataList > .ant-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title > .header-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#CardDataList > .ant-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title > .header-card > .header-action > button {
  border: unset;
  font-size: 16px;
  font-weight: bold;
  box-shadow: unset;
}

#CardDataList > .ant-card > .ant-card-body {
  height: 350px;
  overflow: auto;
}
