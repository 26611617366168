#HeaderStudent {
  background-color: var(--color-white);
  padding: 20px 20px 0 250px;
  position: fixed;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  top: 0;
  /* border-radius: 0 0 15px 15px; */
  /* box-shadow: var(--box-shadow-base); */
}

#HeaderStudent .icon-header {
  height: 40px;
}

#HeaderStudent .ant-space {
  display: flex;
  justify-content: space-between;
}

#HeaderStudent .ant-input-affix-wrapper {
  border-radius: 20px;
}

#HeaderStudent .action-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#HeaderStudent .action-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#HeaderStudent .action-right > .ant-btn {
  margin-left: 10px;
  border: 1px solid #d9d9d9;
  box-shadow: unset;
}
#HeaderStudent .action-right > .ant-btn > svg > path {
  stroke: #c9264b;
}

#HeaderStudent .action-right > .ant-btn-group > .ant-btn {
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
  padding: unset;
}
.drowpdown-button-custom {
  cursor: pointer;
  margin-left: 15px;
}
.user-drawer {
  display: flex;
  line-height: 10px;
  text-align: left;
  align-items: center;
}

.user-drawer > .ant-btn {
  border-radius: 20px !important;
  margin-right: 10px;
  cursor: default;
}

.user-drawer h5.ant-typography {
  font-size: 16px;
  margin: unset;
}
.user-drawer .ant-typography {
  font-size: 12px;
  margin: unset;
}
