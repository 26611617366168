#DetailEntity .section-header {
  padding: 30px 50px;
  background-color: var(--color-background-content);
  border-radius: 20px;
}

#DetailEntity .section-header > .action-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
#DetailEntity .section-header > .action-header > button {
  border: unset;
  box-shadow: unset;
  background-color: unset;
}
#DetailEntity .section-header > .action-header > div > button {
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
  background-color: unset;
}

#DetailEntity .section-header > .information-header {
  display: grid;
}
#DetailEntity .section-header > .information-header > .material {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
#DetailEntity .section-header > .information-header > .material .material-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
}

#DetailEntity .content-detail {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px 30px 50px 30px;
  background-color: var(--color-background-content);
  border-radius: 20px;
}
#DetailEntity .content-detail > .action-content {
  padding: 0 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#DetailEntity .content-detail > .action-content > button {
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
  background-color: unset;
}

#DetailEntity .empty-content {
  width: 40%;
  min-height: max-content;
  text-align: center;
  margin: auto;
  margin-top: 100px;
}

#DetailEntity .empty-content > .content-empty {
  margin-top: 30px;
  margin-bottom: 30px;
}

#DetailEntity .quiz-content .quiz-detail {
  margin-top: 30px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card {
  margin: 0 40px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card .ant-card-body {
  padding: 20px 40px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card .ant-card-body .items-question {
  margin-bottom: 20px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card .ant-card-body .items-question .is-question {
  margin-bottom: 10px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card .ant-card-body .items-question .is-answer {
  padding: 0 20px;
}
#DetailEntity .quiz-content .quiz-detail .ant-card .ant-card-body .items-question .is-answer > div {
  margin-bottom: 5px;
}

