@import '../../variables.css';

#DataList {
  margin-top: 20px;
}
#DataList .ant-list-items > .list-item-clickable:hover {
  cursor: pointer;
  box-shadow: var(--box-shadow-base);
  border-radius: 10px;
  transition: 0.5s;
}

#DataList .ant-list-items > .ant-list-item > .ant-row > .ant-col > h5.ant-typography {
  font-weight: unset;
  font-size: var(--font-size-12);
  color: var(--color-gray);
}

#DataList .ant-list-items > .ant-list-item > .ant-row > .ant-col > div {
  display: flex;
  align-items: center;
}

#DataList .ant-list-items .icon-instructure {
  border: 1px solid var(--color-white);
  background-color: #fbf0f2;
  color: #C9264B;
  padding: 5px;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  text-align: center;
  position: relative;
  margin-right: -10px;
}

#DataList .ant-list-items .learners {
  border: 1px solid var(--color-white);
  background-color: #c9264b12;
  color: #C9264B;
  margin-right: 10px;
  padding: 5px 10px;
  text-align: center;
}

#DataList .ant-list .ant-list-pagination {
  text-align: center;
  margin-bottom: 30px;
}
