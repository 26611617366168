#MainCourse .main-content {
  /* margin-left: 330px; */
  min-height: 100vh;
}
#MainCourseControl {
  height: 93%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--color-background-white);
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  border: 1px solid #ffffff;
}

.mainControl-collapse {
  margin-bottom: 15px;
}
.mainControl-collapse .ant-collapse {
  border-radius: 10px;
}

#MainCourse .mainContent {
  width: 100%;
  padding-left: 20px;
}
#MainCourse .card-loading .ant-card-head {
  background-color: #f0f0f0;
}
#MainCourse .card-loading .ant-card-body {
  padding: 20px;
  background-color: #f0f0f0;
}

#MainCourse .button-drawer {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

#MainCourseHeader {
  padding: 0 30px;
  background-color: var(--color-background-white);
  display: flex;
  position: fixed;
  width: 100%;
  height: 65px;
  z-index: 2;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

#MainCourseHeader.mobile-version {
  padding: 0 10px;
}

#MainCourseHeader .button-back-navigation {
  background: transparent;
  border: unset;
  color: var(--color-primary);
  box-shadow: unset;
}

#MainCourseContent {
  min-height: 100vh;
}

#MainCourseContent .breadcumbs-content {
  padding: 0 30px;
  margin-top: 10px;
  margin-bottom: 20px;
}

#MainCourseContent .breadcumbs-content .ant-breadcrumb .ant-dropdown-trigger {
  cursor: pointer;
}
#MainCourseContent
  .breadcumbs-content
  .ant-breadcrumb
  .ant-dropdown-trigger
  > .anticon.anticon-down {
  display: none;
}
#MainCourseContent .breadcumbs-content .ant-breadcrumb li:last-child {
  color: #c9264b;
}

#MainCourseContent.isVideoContent {
  background: #3f3d3d;
}

#MainCourseContent.isVideoContent #MainContent .content {
  background-color: #000;
  /* padding: 16px; */
  border-radius: 20px;
}
#MainCourseContent.isVideoContent #MainContent .ant-card {
  background: #333333;
  border: 1px solid #333333;
}
#MainCourseContent.isVideoContent
  #MainContent
  .ant-card
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #ffffff;
}

#MainCourseContent.isVideoContent #MainContent .ant-tabs-tab-btn {
  color: #aaaaaa;
}
#MainCourseContent.isVideoContent
  #MainContent
  .ant-card
  .ant-tabs-content-holder
  .ant-tabs-content.ant-tabs-content-top
  .ant-tabs-tabpane
  .ant-typography {
  color: #ffffff;
}
#MainCourseContent.isVideoContent
  #MainContent
  .content
  .content-entity
  .ant-typography {
  color: #ffffff;
}
#MainCourseContent.isVideoContent .breadcumbs-content .ant-breadcrumb {
  color: #ffffff;
}
#MainCourseContent.isVideoContent
  .breadcumbs-content
  .ant-breadcrumb
  .ant-breadcrumb-separator {
  color: #ffffff;
}

#MainCourseContent.isVideoContent
  .breadcumbs-content
  .ant-breadcrumb
  li:last-child {
  color: #ff6a8b;
}
#MainCourseContent.isVideoContent
  .breadcumbs-content
  .ant-breadcrumb
  li:last-child {
  color: #ff6a8b;
}

/* pwa setup */
#MainCourse.mobile-version .main-content {
  margin: unset !important;
}
#MainCourse.mobile-version h4.ant-typography {
  font-size: 1rem;
}
#MainCourse.mobile-version .main-content #MainCourseHeader {
  padding: 0 10px !important;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  z-index: 2;
}
#MainCourse.mobile-version .main-content #MainCourseContent {
  padding: 15px;
  margin-top: 60px;
  margin-bottom: 100px;
}
#MainCourse .main-content #MainCourseContent .breadcumbs-content {
  padding: unset;
  margin-top: unset;
}
#MainCourse
  .main-content
  #MainCourseContent
  .breadcumbs-content
  .ant-breadcrumb
  > ol {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
#MainCourse
  .main-content
  #MainCourseContent
  .breadcumbs-content
  .ant-breadcrumb
  > ol
  > li {
  display: flex;
}
#MainCourse
  .main-content
  #MainCourseContent
  .breadcumbs-content
  .ant-breadcrumb
  > ol
  > li
  > .ant-breadcrumb-link {
  display: block;
  /* width: 250px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#MainCourse
  .main-content
  #MainCourseContent
  .breadcumbs-content
  .ant-breadcrumb
  > ol
  > li
  > .ant-breadcrumb-overlay-link
  > .ant-breadcrumb-link {
  display: block;
  /* width: 250px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#MainCourse
  .main-content
  #MainCourseContent
  .breadcumbs-content
  .ant-breadcrumb
  > ol
  > li
  > .ant-breadcrumb-separator {
  display: block;
}
#MainCourse
  .main-content
  #MainCourseContent
  .breadcumbs-content
  .ant-breadcrumb
  > ol
  > li:last-child
  > .ant-breadcrumb-separator {
  display: none !important;
}

.ant-popover .ant-popover-inner-content .quick-content-breadcumbs {
  width: 300px;
  height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-popover
  .ant-popover-inner-content
  .quick-content-breadcumbs
  .ant-collapse {
  background-color: transparent;
  border: unset;
}
.ant-popover
  .ant-popover-inner-content
  .quick-content-breadcumbs
  .ant-collapse
  .ant-collapse-item {
  margin-bottom: 10px;
  border: unset;
  background-color: #ffffff;
  border-radius: 8px;
}
.ant-popover
  .ant-popover-inner-content
  .quick-content-breadcumbs
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-header-text {
  font-size: 14px;
}
.ant-popover
  .ant-popover-inner-content
  .quick-content-breadcumbs
  .ant-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-header-text {
  color: #c9264b;
}
.ant-popover
  .ant-popover-inner-content
  .quick-content-breadcumbs
  .ant-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-expand-icon
  > span
  > svg {
  fill: #c9264b;
}
.ant-popover
  .ant-popover-inner-content
  .quick-content-breadcumbs
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-content {
  background-color: transparent;
}
.ant-popover
  .ant-popover-inner-content
  .quick-content-breadcumbs
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  padding: 16px;
}
