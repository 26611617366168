#MainContent {
  margin-bottom: 30px;
  width: 100%;
}

#MainContent .content {
  width: 100%;
  overflow: auto;
  background-color: #fff;
  border-radius: 12px;
  /* padding: 20px 30px 20px 50px; */
  margin-bottom: 20px;
}

#MainContent .extra-content {
  padding: unset;
}

#MainContent .content .content-activity .ant-list {
  padding: 0 20px;
}
#MainContent
  .content
  .content-activity
  .ant-list
  .ant-list-items
  .ant-list-item.link-items {
  cursor: pointer;
}
#MainContent
  .content
  .content-activity
  .ant-list
  .ant-list-items
  .ant-list-item.link-items:hover
  > .ant-typography {
  color: #bd214c;
}
/*
#MainContent .content .content-entity .title-content {
  display: flex;
  align-items: center;
  justify-content: ;
  margin-bottom: 10px;
} */

#MainContent .content .content-entity .title-content h4.ant-typography {
  margin: unset;
}

#MainContent .content .content-entity .button-link-jump-prev {
  padding: 0 5px;
}
#MainContent .content .content-entity .button-link-jump-prev:focus {
  border: unset;
}

#MainContent .content .content-entity .content-6 .material-link-assignment {
  width: 100%;
  height: 500px;
}
#MainContent .content .content-entity .content-6 .material-link-assignment iframe {
  width: 100%;
  height: 500px;
}

/* PWA setup */
#MainContent .content.mobile {
  height: auto !important;
  min-height: unset !important;
  /* padding: 20px 15px; */
}

#MainContent .content.mobile .content-entity .title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#MainContent .content.mobile .content-entity .title-content h4.ant-typography {
  font-size: 16px;
}

#MainContent .button-content {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 30px;
}

.button-content-navigation {
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 380px;
}