#DetailQuiz .header-quiz {
  padding: 20px 30px;
  background-color: var(--color-background-content);
  border-radius: 20px;
}

#DetailQuiz .header-quiz>.action-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

#DetailQuiz .header-quiz>.action-header>button {
  border: unset;
  box-shadow: unset;
  background-color: unset;
}

#DetailQuiz .header-quiz>.action-header>div>button {
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
  background-color: unset;
}

#DetailQuiz .header-quiz>.information-header {
  display: flex;
  align-items: flex-start;
}

#DetailQuiz .header-quiz>.information-header>.ant-image {
  padding: 30px;
  background-color: var(--color-primary);
  border-radius: 10px;
}

#DetailQuiz .header-quiz>.information-header>.content-header {
  width: 100%;
}

#DetailQuiz .header-quiz>.information-header>.content-header .status-quiz {
  padding: 5px 20px;
  width: fit-content;
  background: var(--color-primary);
  color: var(--color-white);
  font-size: 14px;
  border-radius: 20px;
  margin-bottom: 15px;
}

#DetailQuiz .question-panel {
  margin-top: 30px;
}

#DetailQuiz .question-panel .panel-control {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

#DetailQuiz .content-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 30px 30px 50px 30px;
  background-color: var(--color-background-content);
  border-radius: 20px;
}
#DetailQuiz .content-detail .panel-question {
  width: 100%;
  position: relative;
  padding-left: 30px;
}

#DetailQuiz .content-detail .action-button-question {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 170px;
  gap: 10px
}
#DetailQuiz .content-detail .question-panel-number {
  width: 420px;
}

#DetailQuiz .content-detail .question-panel-content {
  flex-basis: 75%;
}

#DetailQuiz .content-detail .question-panel-number .playground-numbering {
   padding: 10px;
   background-color: #f9f9f9;
   border-radius: 10px;
   margin-bottom: 10px;
}

#DetailQuiz .content-detail .question-panel-number .playground-numbering .question-number-list {
  width: 420px;
  height: 250px;
  overflow: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}
/* #DetailQuiz .content-detail .question-panel-number .playground-numbering .question-number-list .number-list {
  flex-basis: content;
  display: grid;
  grid-template-columns: repeat(10, 35px);
  grid-gap: 5px;
} */
#DetailQuiz .content-detail .question-panel-number .playground-numbering .question-number-list .number-list .question-number-button {
  width: 35px;
  padding: 0 5px;
}

#DetailQuiz .content-detail .button-add-more-section {
  border: 1px solid #C9264B;
}

#DetailQuiz .content-detail .button-add-more-section2 {
  margin-top: 2rem;
}

#DetailQuiz .question-panel .modal-set-question {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 15px;
}

#DetailQuiz .content-detail .form-question {
  width: 100%;
  background: var(--color-white);
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 20px !important;
  margin: auto;
}
#DetailQuiz .content-detail .form-question .ant-card-body {
  padding: 0;
}

#DetailQuiz .content-detail .numbering-question {
  width: 40px;
  height: 30px;
  background: var(--color-primary);
  border-radius: 100px;
  text-align: center;
  position: absolute;
  top: 30px;
  left: -20px;
  font-size: 16px;
  color: white;
}

#DetailQuiz .content-detail .form-question .main-header .question-input {
  padding: 15px;
  border-radius: 10px;
}

#DetailQuiz .content-detail .form-question .main-header .control-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

#DetailQuiz .content-detail .form-question .main-header .control-main .ant-input-group .ant-row {
  margin: unset;
}

#DetailQuiz .content-detail .form-question .main-header .control-main .ant-select-selector {
  border-radius: 10px 0 0 10px;
}

#DetailQuiz .content-detail .form-question .main-header .control-main .ant-select-selector {
  border-radius: 10px 0 0 10px;
}

#DetailQuiz .content-detail .form-question .main-header .control-main .ant-input-affix-wrapper {
  border-radius: 0 10px 10px 0;
}

#DetailQuiz .content-detail .form-question .main-asnwer {
  padding-top: 10px;
  margin-top: 20px;
  border-top: 2px solid var(--color-primary);
}

#DetailQuiz .content-detail .form-question .main-asnwer .populate-answer {
  padding: 20px 0;
}

#DetailQuiz .content-detail .form-question .main-asnwer .populate-answer .answer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: var(--color-background-content);
  border-radius: 10px;
  margin-bottom: 10px;
}

#DetailQuiz .content-detail .form-question .main-asnwer .populate-answer .answer-item .item-answer {
  width: 100%;
  display: flex;
  align-items: center;
}

#DetailQuiz .content-detail .form-question .main-asnwer .populate-answer .answer-item .item-answer .ant-input {
  margin: 0 15px;
  border: unset;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 10px;
  background-color: unset;
}

#DetailQuiz .content-detail .form-question .main-asnwer .control-answer {
  margin-bottom: 20px;
  text-align: right;
}

#DetailQuiz .content-detail .form-question .main-asnwer .control-answer>.ant-form .ant-input-group .ant-input-affix-wrapper {
  border-radius: 10px 0 0 10px;
}

#DetailQuiz .content-detail .form-question .main-asnwer .control-answer>.ant-form .ant-input-group .ant-btn {
  border-radius: 0 10px 10px 0;
}

/* Pairing Answer */

.pairing-answer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
