@import '../../variables.css';

#AuthLayout {
  min-height: 100vh;
}

#AuthLayout .footer {
  text-align: center;
}

#AuthLayout .ant-layout-footer .ant-typography {
  font-size: var(--font-size-12);
}

#authForm {
  min-height: 100vh;
  display: grid;
  align-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 50px 50px 20px 50px;
  text-align: left;
}

#authForm .section-content {
  margin-top: 50px;
}
#authForm .section-content .image-content{
  margin: unset;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

#authForm .ant-card {
  width: 400px;
  height: fit-content;
  background-color: #fcfcfc;
  border-radius: 20px;
  box-shadow: var(--box-shadow-base);
  margin: unset;
}

#authForm .ant-card .ant-card-body {
  padding: 30px 20px;
}

#authForm .ant-card .ant-card-body .section-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  height: 450px;
  padding: 0 30px;
}

#authForm .ant-card .ant-card-body .section-form h5.ant-typography {
  color: var(--color-primary);
}

#authForm .ant-card .ant-card-body .section-form .form-auth {
  width: 100%;
}

#authForm .ant-card .ant-card-body .section-form .form-auth .ant-form-item-extra {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#authForm .ant-card .ant-card-body .section-form .form-auth .ant-form-item {
  margin-bottom: 15px;
}
#authForm .ant-card .ant-card-body .section-form .form-auth .ant-form-item-extra > .ant-btn {
  padding: unset;
}

#authForm .ant-card .ant-card-body .section-form .form-auth .btn-forgot {
  float: right;
  color: var(--color-primary);
}

#authForm .ant-card .ant-card-body .section-form .form-auth .button-signin {
  background-color: var(--color-primary);
  color: white;
  font-weight: bold;
}

#authForm .ant-card .ant-card-body .auth-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
}
#authForm .ant-card .ant-card-body .auth-image .img-landing {
  width: 100%;
}

/* mobile version */
.header-login {
  position: sticky;
  top: 0px;
  z-index: 1;
  width: 100%;
  padding: 10px 20px !important;
  background: #FFFFFF !important;
}
#authForm.mobile-version {
  padding: 50px 20px 20px 20px;
  background-color: #ffffff;
}
#authForm.mobile-version .ant-card {
  width: 100%;
  box-shadow: unset;
  border: unset;
  background-color: #ffffff;
}
#authForm.mobile-version .ant-card .ant-card-body {
  text-align: center;
}
#authForm.mobile-version .ant-card .ant-card-body .section-form{
  padding: unset;
}

.landing-mobile {
  background-color: #CA274C;
  height: 100vh;
  padding: 100px 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.landing-mobile .logo-landing {
  text-align: center;
  margin-bottom: 20px;
}
.landing-mobile .text-landing {
  text-align: center;
}
.landing-mobile .text-landing .ant-typography {
  color: white;
  font-size: 16px;
}
.landing-mobile .btn-landing{
  position: fixed;
  width: 90%;
  bottom: 100px;
}
