@import '../../../variables.css';

#DetailPrograms .header-programs {
  background-color: var(--color-background-content);
  border-radius: 10px;
  padding: 20px 30px;
  background-image: url('https://bolehbelajar.com/wp-content/uploads/2022/03/bootcamp-banner-resize.png');
  background-color: var(--color-primary);
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  display: grid;
  align-content: space-between;
  margin-bottom: 30px;
}
#DetailPrograms .header-programs > h2.ant-typography {
  margin: unset;
}

#DetailPrograms .header-programs > .action-header {
  text-align: right;
}
#DetailPrograms .header-programs > .action-header > button {
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
  font-weight: bold;
}

#DetailPrograms .information-deck {
  padding: 30px;
  background-color: #ffffffa3;
  border-radius: 10px;
}
#DetailPrograms .information-deck > .deck-status {
  text-align: center;
  font-size: 18px;
}
#DetailPrograms .information-deck > .deck-status > .ant-row > .ant-col {
  padding: 10px;
}
#DetailPrograms .information-deck > .deck-status > .ant-row > .ant-col > div {
  border: 1px solid var(--color-primary);
  padding: 15px 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#DetailPrograms
  .information-deck
  > .deck-status
  > .ant-row
  > .ant-col
  > div
  > .circle-title {
  height: 40px;
  width: 40px;
  background-color: var(--color-primary);
  border-radius: 100%;
  margin-right: 15px;
  padding: 5px;
  color: white;
}

#DetailPrograms .empty-content {
  width: 40%;
  min-height: max-content;
  text-align: center;
  margin: auto;
  margin-top: 100px;
}

#DetailPrograms .empty-content > .content-empty {
  margin-top: 30px;
  margin-bottom: 30px;
}

#DetailPrograms .content-detail {
  margin-bottom: 20px;
}
#DetailPrograms .content-detail > .section-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
}
#DetailPrograms .content-detail > .section-header > .title {
  /* display: flex; */
  align-items: center;
}
#DetailPrograms .content-detail > .section-header > .title > span {
  /* margin-left: 10px; */
}
#DetailPrograms .content-detail > .section-header > div > button {
  border: unset;
  /* box-shadow: unset;
  background-color: unset; */
}
#DetailPrograms .content-detail > .section-header > div > .switch-activity {
  margin-right: 10px;
  height: 30px;
  width: 100px;
}
#DetailPrograms .content-detail > .section-header > div > .switch-activity .ant-switch-handle {
  top: 5px;
  left: 5px;
}
#DetailPrograms .content-detail > .section-header > div > .switch-activity.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 22px - 2px) !important;
}

#DetailPrograms .ant-card {
  margin-bottom: 20px;
}
#DetailPrograms .ant-card .ant-card-body {
  padding: 10px 30px;
}
#DetailPrograms
  .ant-card
  .ant-card-body
  .ant-form
  .ant-row
  .form-action-button {
  text-align: right;
}
#DetailPrograms
  .ant-card
  .ant-card-body
  .ant-form
  .ant-row
  .form-action-button
  > .ant-btn {
  margin-left: 10px;
}
