#StudentDashboard .card-upcoming-events {
  /* position: fixed; */
  /* width: 400px; */
  background-color: #f6f6f6;
}
#StudentDashboard .right-information {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  padding: 0 15px 15px 15px;
}

#StudentDashboard .card-dashboard {
  background-color: white;
  margin-bottom: 30px;
}
#StudentDashboard .card-dashboard .ant-card-body {
  padding: 0 40px 40px 40px;
}

#StudentDashboard .highlight-course {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
#StudentDashboard .highlight-course h3.ant-typography {
  margin: unset;
}

#side-menu {
  background-color: var(--color-white);
  border-right: 1px solid #dedede;
  position: fixed;
  z-index: 2;
}

#side-menu .ant-menu-item {
  color: #333;
}
#side-menu .ant-menu-item-selected {
  color: var(--color-primary);
}
#side-menu .ant-menu-item:hover {
  color: var(--color-primary);
}

#side-menu .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 32px;
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
