@import '../../variables.css';

.customTour .introjs-button {
  text-shadow: unset;
  border-radius: 10px;
  border: unset;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
}

.introjs-tooltip {
  margin-top: 12px;
  max-width: 600px !important;
  width: 540px;
}

.customTour .introjs-tooltip-title {
  line-height: unset;
}

.customTour .introjs-skipbutton {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: unset;
}

.customTour .introjs-nextbutton {
  background: var(--color-primary);
  color: #ffffff;
}

.customTour .introjs-button.introjs-prevbutton {
  background: transparent;
  color: var(--color-primary);
}

.customTour .introjs-button.introjs-nextbutton:hover {
  outline: 0;
  text-decoration: none;
  background-color: #ac2040;
  color: #ffffff;
}

.customTour .introjs-button.introjs-prevbutton:hover {
  outline: 0;
  text-decoration: none;
  background-color: #f1f3f6;
  color: var(--color-primary);
}

.customTour .introjs-button.introjs-nextbutton:focus {
  outline: 0;
  text-decoration: none;
  background-color: #ac2040;
  color: #ffffff;
}

.customTour .introjs-button:focus {
  box-shadow: unset;
  border: unset;
}

.customTour .introjs-bullets ul li a.active {
  width: 15px;
  background: var(--color-primary);
}

.customTour .introjs-bullets ul li a {
  transition: width 0.1s ease-in;
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #f7d4dc;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .introjs-tooltip {
    width: 376px !important;
  }

  .customTour.introjs-top-middle-aligned {
    position: fixed;
    top: 50%;
    left: 50% !important;
    bottom: unset !important;
    margin-top: 158px;
    transform: translate(-50%, -50%);
  }
}
