#DataTableDragable .ant-list .ant-list-items .ant-list-item {
  background-color: inherit;
  border-bottom: 1px solid #c759724a;
  padding: 15px 15px;
}
#DataTableDragable .item-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#DataTableDragable .item-data > .item-control {
  display: flex;
  align-items: center;
}
#DataTableDragable .item-data > .item-control > .text-info {
  margin-left: 10px;
}
#DataTableDragable .item-data > .item-control > .text-info > h5.ant-typography {
  margin: unset;
}
#DataTableDragable .item-data > .item-control > .text-info > h5.ant-typography.title-link {
  cursor: pointer;
}
#DataTableDragable .item-data > .item-control > .text-info > h5.ant-typography.title-link:hover {
  color: var(--color-primary);
}
#DataTableDragable .item-data > .item-control .icon-list {
  margin-left: 10px;
  height: 30px;
  width: 30px;
  padding: 5px;
  background-color: var(--color-primary);
  border-radius: 5px;
  text-align: center;
  color: white;
}
#DataTableDragable .item-data > .item-control .icon-list > span {
  font-size: 20px;
}
#DataTableDragable .item-data > .item-action {
  display: flex;
  align-items: center;
}
#DataTableDragable .item-data > .item-action > .ant-select .ant-select-selector {
  border: unset;
  background-color: unset;
}

.entity-type-selection {
  padding: 0px 15px;
  border-radius: 10px;
  color: white;
  width: fit-content;
  float: right;
}

.entity-type-selection-1 {
  background-color: var(--color-success);
}
.entity-type-selection-2 {
  background-color: var(--color-warning);
}
