.header-custom > h3.ant-typography {
  margin-bottom: unset;
}

.header-custom > span {
  font-size: 14px;
  font-weight: normal;
}

#ModalEdit > .ant-row > .ant-col > .ant-form-item-control-input > .ant-form-item-control-input-content > .form-action-button {
  text-align: right;
}
#ModalEdit > .ant-row > .ant-col > .ant-form-item-control-input > .ant-form-item-control-input-content > .form-action-button > button {
  margin-left: 10px;
}