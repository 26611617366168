#VideoPlayerCustom {
  position: relative;
  background-color: black;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  min-height: 600px;

  .iframe-player {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: unset;
  }
}

// PWA Setup
#VideoPlayerCustom.mobile {
  height: 200px !important;
  min-height: unset !important;
}
