#AdminPrograms .empty-content {
  width: 30%;
  min-height: max-content;
  text-align: center;
  margin: auto;
  margin-top: 100px;
}

#AdminPrograms .empty-content > .content-empty {
  margin-top: 30px;
  margin-bottom: 30px;
}

#AdminPrograms .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

#AdminPrograms .ant-card {
  border-radius: 0px 10px 10px 10px;
}

#AdminPrograms .ant-card-bordered {
  border: 0;
}

#AdminPrograms .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 10px 10px 0px 0px;
  color: #6B6B6B;
  line-height: 16.94px;
  font-weight: 700;
  font-size: 14px;
}

#AdminPrograms .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
  color: #c4264d;
}

#AdminPrograms .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #c4264d;
  line-height: 16.94px;
  font-weight: 700;
  font-size: 14px;
  padding: 5px;
}

#AdminPrograms .ant-tabs-tab div {
  padding: 5px;
}

#AdminPrograms .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  color: #c4264d;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  background-color: #EAEAEA;
}

#AdminPrograms .vlc-content, .bootcamp-content {
  width: 50%;
}

#AdminPrograms .bootcamp-content .collect-switch, .config-switch, .score-ratio {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

#AdminPrograms .vlc-content .collect-switch, .config-switch, .score-ratio {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
