.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #dedede;
  padding-top: 3.5vw;
}

.border-outer {
  border: 2px solid red;
  padding: 10px;
  width: auto !important;
  border-radius: 0.625rem;
}

.border-inner {
  border: 2px solid rgba(234, 26, 73, 0.5);
  border-radius: 0.625rem;
  padding: 10px;
  width: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-quicksand {
  font-family: 'Quicksand', sans-serif;
  width: 100%;
  margin-bottom: 1rem;
}

.font-quicksand-content {
  font-family: 'Quicksand', sans-serif;
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 4.25rem;
}

.hide {
  display: none;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

#title-grey {
  font-size: 1.125rem;
  color: #7b7777;
}

#title-black {
  font-size: 1.25rem;
  color: #333333;
}

#text-grey {
  font-size: 1.125rem;
  color: #7b7777;
}

#text-black {
  font-size: 1.5rem;
  color: #333333;
}

@media only screen and (max-width: 768px) {
  .font-quicksand {
    width: 100%;
    padding-right: 10px;
    margin-bottom: 0.5rem;
  }

  .font-quicksand-content {
    width: 100%;
  }

  #title-grey {
    font-size: 0.875rem;
  }

  #title-black {
    font-size: 1rem;
  }

  #text-black {
    font-size: 1.125rem;
  }

  #text-grey {
    font-size: 0.875rem;
  }

  .border-inner,
  .border-outer {
    border: none;
    padding: 0;
  }
}

.PDF canvas {
  max-width: 100% !important;
  height: auto !important;
  border-radius: 10px;
}

#linkedin-cta {
  margin-top: 2rem;
  margin-bottom: 3rem;
  padding-top: 1rem;
  padding-bottom: 2.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
