#fwrd-content {
  width: 250px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#fwrd-content .ant-list {
  margin-top: 20px;
}
#fwrd-content .ant-list .ant-spin-nested-loading .ant-spin .ant-spin-dot {
  top: 0%;
  left: 60%;
}
#fwrd-content .ant-list .ant-spin-nested-loading .ant-spin .ant-spin-dot .ant-spin-dot-item {
  background-color: var(--color-primary);
}
