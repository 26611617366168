#cardPrograms .card-highlight {
  background-color: white !important;
  margin-bottom: 20px;
  cursor: pointer;
}
#cardPrograms .card-highlight:hover {
  box-shadow: var(--box-shadow-base);
}
#cardPrograms .card-highlight .ant-card-head-title {
  white-space: normal;
}
#cardPrograms .card-highlight .ant-card-head-title .moderator-name{
  font-weight: normal;
}
#cardPrograms .card-highlight .ant-card-body {
  padding: 15px !important;
}
#cardPrograms .card-highlight .ant-card-body .content-body{
  margin-top: 20px;
  padding: unset;
  position: relative;
  height: 200px;
}
#cardPrograms .card-highlight .ant-card-body .content-body .program-type {
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
}
#cardPrograms .card-highlight .ant-card-body .content-body .program-type.type-1 {
  background: rgba(201, 38, 75, 0.1);
  color: #C9264B;
}
#cardPrograms .card-highlight .ant-card-body .content-body .program-type.type-2 {
  background: rgba(44, 190, 164, 0.1);
  color: #2CBEA4;
}
#cardPrograms .card-highlight .ant-card-body .content-body .program-type.type-3 {
  background: rgba(75, 150, 255, 0.1);
  color: #4B96FF;
}
#cardPrograms .card-highlight .ant-card-body .content-body .program-type.type-4 {
  background: rgba(255, 75, 183, 0.1);
  color: #ff4ba8;
}
#cardPrograms .card-highlight .ant-card-body .btn-action-class{
  position: absolute;
  bottom: 30px;
  width: 85%;
  text-align: center;
}
#cardPrograms .card-highlight .ant-card-body .image-content{
  width: 100%;
  height: 200px;
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 10px;
}

#cardPrograms.mobile-version .ant-row {
  display: block;
}
