#NumberingDragable > div {
    flex-basis: content;
    display: grid;
    grid-template-columns: repeat(10, 35px);
    grid-gap: 5px;
}
#NumberingDragable > div .object-numbering {
    width: 32px;
    height: 32px;
    padding: 0 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 10px;
    border: solid 1px #d0cece;
}

#NumberingDragable > div .object-numbering.selected {
    background: #c9264b;
    color: white;
    border-radius: 10px;
    border: solid 1px #c9264b;
}