.header-custom > h3.ant-typography {
  margin-bottom: unset;
}

.header-custom > span {
  font-size: 14px;
  font-weight: normal;
}

#ModalCreateActivity > .ant-row > .ant-col > .ant-form-item-control-input .ant-form-item-control-input-content .form-action-button {
  text-align: right;
}
#ModalCreateActivity > .ant-row > .ant-col > .ant-form-item-control-input .ant-form-item-control-input-content .form-action-button button {
  margin-left: 10px;
}

.ant-popover .ant-popover-inner-content {
  width: 350px;
}
.ant-popover .ant-popover-inner-content .lesson-list {
  height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-popover .ant-popover-inner-content .item-lesson-existing {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}