#container-pdf {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  align-items: flex-start;
}

.PDFPage > canvas {
  max-width: 100%;
  height: auto !important;
  border-radius: 10px;
}

.cert-card {
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  transition-duration: 150ms;
}

.cert-card:hover {
  cursor: pointer;
  filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03))
    drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ant-card-actions {
  background: transparent !important;
}
