#DetailLearner .ant-collapse-content>.ant-collapse-content-box {
  padding: 0;
}

#DetailLearner .list-item {
  display: flex;
  border: 1px solid rgb(227, 227, 227);
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

#DetailLearner .list-item .list-item-contents {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#DetailLearner .list-item .list-item-contents h3 {
  margin-bottom: 0px;
  margin-left: 20px;
}

#DetailLearner .list-item .list-item-contents .action-item-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 40%; */
}

#DetailLearner .list-item .list-item-contents .action-item-contents .list-item-score {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#DetailLearner .list-item .list-item-contents .action-item-contents .list-item-button {
  width: 300px;
  text-align: right;
}

#DetailLearner .list-item button {
  border-radius: 50px;
  background: white;
  color: #C9264B;
}

#DetailLearner .list-item button.disabled {
  color: #A8A8A8;
}

#DetailLearner .list-item button.success {
  color: #389517;
  border-color: #389517;
}

#DetailLearner .list-item .list-item-contents button.success .anticon svg {
  fill: #389517;
}

#DetailLearner .list-item .list-item-contents .anticon svg {
  fill: #C9264B;
}

#DetailLearner .ant-collapse-expand-icon svg {
  fill: #C9264B;
}

.submit-score .ant-modal-content .ant-modal-footer {
  text-align: left !important;
}

.quiz-history .ant-modal-content .ant-modal-footer {
  text-align: left !important;
}

