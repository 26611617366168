@import '../../../variables.css';

#AdminLessons .empty-content {
  width: 30%;
  min-height: max-content;
  text-align: center;
  margin: auto;
  margin-top: 100px;
}

#AdminLessons .empty-content > .content-empty {
  margin-top: 30px;
  margin-bottom: 30px;
}

#DataList .ant-list-items > .ant-list-item > .ant-row > .ant-col > div {
  display: -webkit-box;
}