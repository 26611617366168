@import '../../variables.css';

#BaseLayout {
  min-height: 100vh;
  width: 100vw;
}

/* #BaseLayout .header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

#BaseLayout .header-content .header-action > button {
  margin-left: 10px;
}
#BaseLayout .header-content .header-action > div {
  margin-left: 10px;
}

#BaseLayout .header-action-multiple {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

#BaseLayout .header-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}

#ContentLayout {
  position: relative;
  padding-top: 20px;
}
