.header-custom>h3.ant-typography {
  margin-bottom: unset;
}

.header-custom>span {
  font-size: 14px;
  font-weight: normal;
}

#createEntity>.ant-row>.ant-col>.ant-form-item-control-input>.ant-form-item-control-input-content>.form-action-button {
  text-align: right;
}

#createEntity>.ant-row>.ant-col>.ant-form-item-control-input>.ant-form-item-control-input-content>.form-action-button>button {
  margin-left: 10px;
}

#createEntity .item-description {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

#createEntity .item-description .description {
  width: 100%;
}

#createEntity .item-description .duration {
  width: 200px;
  margin-left: 10px;
}

#createEntity .item-description .action-btn {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

#createEntity .item-description .action-btn .ant-btn {
  margin-left: 5px;
}