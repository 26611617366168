#CardListDataFlex {
  margin-bottom: 50px;
}
#CardListDataFlex .ant-list .ant-list-pagination {
  text-align: center;
}
#CardListDataFlex .ant-list .ant-list-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#CardListDataFlex .ant-list .ant-list-item {
  width: 350px;
  border: unset;
  position: relative;
}

#CardListDataFlex .ant-card {
  margin-bottom: 15px;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
#CardListDataFlex .ant-card:hover {
  box-shadow: var(--box-shadow-base);
}
#CardListDataFlex .ant-card .ant-card-body .image-content{
  width: 100%;
  height: 200px;
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 10px 10px 0 0;
}
#CardListDataFlex .button-extra{
  position: absolute;
  right: 30px;
  top: 20px;
  padding: 10px;
  z-index: 1;
}
#CardListDataFlex .ant-card .ant-card-body .title-content{
  padding: 10px 20px;
}
#CardListDataFlex .ant-card .ant-card-body .completely-content{
  padding: 10px 20px;
  margin-bottom: 10px;
}

.pop-action {
  width: 100px !important;
}
.ant-popover .ant-popover-content .ant-popover-inner {
  border-radius: 15px;
}
.ant-popover .ant-popover-content .ant-popover-inner-content {
  width: auto;
  display: grid;
  justify-content: start;
  justify-items: start;
}
.ant-popover .ant-popover-content .ant-popover-inner-content .ant-btn {
  margin-bottom: 5px;
}
