#StudentCalendarEvents {
  width: 60%;
  margin: auto;

  .calendar-content {
    display: flex;
    .section-calendar {
      .rmdp-wrapper {
        .rmdp-top-class {
          .rmdp-calendar {
            .rmdp-header {

              .rmdp-arrow-container:hover {
                padding: 2px;
              }
              .rmdp-arrow-container:hover {
                background-color: #C9264B;
                .rmdp-arrow {
                  border-color: #ffffff !important;
                }
              }
              .rmdp-arrow-container.disabled:hover {
                background-color: transparent !important;
                .rmdp-arrow {
                  border-color: #7B7777 !important;
                }
              }

              .rmdp-arrow-container.rmdp-left {
                left: unset;
                right: 30px;
                .rmdp-arrow {
                  border-color: #C9264B;
                }
              }
              .rmdp-arrow-container.rmdp-left.disabled {
                .rmdp-arrow {
                  border-color: #7B7777;
                }
              }

              .rmdp-arrow-container.rmdp-right {
                .rmdp-arrow {
                  border-color: #C9264B;
                }
              }

              .rmdp-header-values {
                margin: unset;
                margin-left: 8px;
                margin-right: auto;
                font-weight: 600;
              }
            }

            .rmdp-day-picker {
              .rmdp-week {
                .rmdp-week-day {
                  color: #C9264B;
                }

                .rmdp-day.rmdp-today {
                  span {
                    background-color: #c9264b6e;
                  }
                }

                .rmdp-day.rmdp-selected {
                  span {
                    background-color: #C9264B;
                  }
                }
                .rmdp-day.rmdp-selected:hover {
                  span {
                    background-color: #c9264ba8;
                  }
                }

                .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
                  background-color: #c9264ba8;
                }
              }
            }
          }
        }
      }
    }

    .section-event {
      margin-left: 30px;
      width: 100%;
      height: 650px;
      overflow-x: hidden;
      overflow-y: auto;

      .ant-list {
        .ant-list-items {
          .ant-list-item {
            display: inline;
          }
        }
      }

      .title-events {
        font-size: 14px;
        font-weight: 400;
        color: #C9264B;
      }

      .entity-data {
        padding: 10px 15px 20px 0;

        .ant-list {
          .ant-list-empty-text {
            text-align: left;
            padding: unset;
          }
          .ant-list-items {
             .ant-card {
              margin-bottom: 20px;

              .ant-card-body {
                padding: 15px 20px;
                .content-data {
                  display: flex;
                  align-items: flex-start;

                  .icon {
                    width: 50px;
                  }
                  .information {
                    margin-left: 15px;

                    .hour {
                      color: #7B7777;
                    }

                    .title {
                      h4.ant-typography {
                        margin: unset;
                      }
                    }

                    .program {
                      .ant-typography {
                        color: #7B7777;
                      }
                    }

                    .action-button {
                      margin-top: 20px;
                      .ant-btn {
                        border-radius: 5px;
                        padding: 5px 10px;
                        span {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }
              }
             }
          }
        }
      }
    }
  }

  .calendar-content.mobile-version {
    display: inline;

    .section-event {
      margin: unset;
      margin-top: 20px;
    }

    .section-calendar {
      .rmdp-wrapper {
        width: 100%;
        .rmdp-top-class {
          display: block !important;
          .rmdp-calendar {
            height: 300px;

            .rmdp-header {

              .rmdp-header-values {
                font-size: 16px;
              }
            }

            .rmdp-day-picker {
              width: 100%;
              display: block !important;
              padding: 10px 30px;
            }

            .rmdp-week-day {
              color: #C9264B;
              font-size: 14px;
            }

            .rmdp-day {
              width: 32px;
              height: 32px;
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

#StudentCalendarEvents.mobile-version  {
  width: 100%;
  .calendar-content {
    display: inline;

    .section-event {
      margin: unset;
      margin-top: 20px;

      .entity-data {
        padding: 10px 0 20px 0 !important;
      }
    }

    .section-calendar {
      .rmdp-wrapper {
        width: 100%;
        .rmdp-top-class {
          display: block !important;
          .rmdp-calendar {
            height: 300px;

            .rmdp-header {

              .rmdp-header-values {
                font-size: 16px;
              }
            }

            .rmdp-day-picker {
              width: 100%;
              display: block !important;
              padding: 10px 30px;
            }

            .rmdp-week-day {
              color: #C9264B;
              font-size: 14px;
            }

            .rmdp-day {
              width: 32px;
              height: 32px;
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
