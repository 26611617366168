#MobileNavigation {
  height: 100px;
  position: fixed;
  bottom: 0;
  z-index: 9;
  width: 100%;
  background: white;
  padding: 15px 0;

  .custom-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    .collection-menu-item {
      display: inline-grid;
      justify-items: center;
      line-height: 34px;
      font-weight: 500;
      color: #7B7777;
      cursor: pointer;
    }

    .collection-menu-item.item-selected {
      color: #C9264B;
      svg > path {
        stroke: #C9264B;
      }
    }

    .collection-menu-item:hover {
      color: #C9264B;
      svg > path {
        stroke: #C9264B;
      }
    }

  }
}
