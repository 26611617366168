#DetailLesson .header-lesson {
  border-radius: 10px;
  padding: 20px 30px;
  /* background-image: url('https://bolehbelajar.com/wp-content/uploads/2022/03/bootcamp-banner-resize.png'); */
  background-color: var(--color-primary);
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: grid;
  align-content: space-between;
}

#DetailLesson .header-lesson > .action-header {
  text-align: right;
}
#DetailLesson .header-lesson > .action-header > button {
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
  font-weight: bold;
}

#DetailLesson .header-lesson > .information-header {
  padding: 30px;
  background-color: #ffffffa3;
  border-radius: 10px;
}
#DetailLesson .header-lesson > .information-header > .ant-image {
  padding: 30px;
  background-color: var(--color-primary);
  border-radius: 10px;
}
#DetailLesson .header-lesson > .information-header > .content-header {
  width: 60%;
}
#DetailLesson .header-lesson > .information-header > .content-header .ant-typography {
  /* color: white; */
}
#DetailLesson .header-lesson > .information-header > .content-header .status-lesson {
  padding: 5px 20px;
  width: fit-content;
  height: fit-content;
  background: var(--color-primary);
  color: var(--color-white);
  font-size: 12px;
  border-radius: 20px;
}
#DetailLesson .header-lesson > .information-header > .content-header .form-edit {
  width: 450px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--color-background-content);
}

#DetailLesson .content-detail {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px 0 50px 0;
  background-color: var(--color-background-content);
  border-radius: 10px;
}
#DetailLesson .content-detail > .action-content {
  padding: 0 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#DetailLesson .content-detail > .action-content > button {
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
  background-color: unset;
}

#DetailLesson .empty-content {
  width: 40%;
  min-height: max-content;
  text-align: center;
  margin: auto;
  margin-top: 100px;
}

#DetailLesson .empty-content > .content-empty {
  margin-top: 30px;
  margin-bottom: 30px;
}

#DetailLesson .editLessonForm {
  margin-top: 20px;
}
#DetailLesson .editLessonForm .form-action-button {
  text-align: right;
}
#DetailLesson .editLessonForm .form-action-button > button {
  margin-left: 10px;
}
