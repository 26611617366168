#CustomApp {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #e9e9e9;
}

#CustomApp > .ant-card > .ant-card-body > .ant-list .ant-list-item .ant-card > .ant-card-body > .detailProduct > .description {
  width: 100%;
  height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#CustomApp > .ant-card > .ant-card-head .ant-card-head-title > .title-playground {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#CustomApp > .ant-card > .ant-card-body > .ant-list .ant-spin-container {
  height: 600px;
}

#productListItem:hover {
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
#productListItem.product-selected {
  box-shadow: 0 2px 8px rgb(255 0 0 / 60%);
}
