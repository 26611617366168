@import '~antd/dist/antd.css';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';
@import '~intro.js/introjs.css';
/* @import './theme-ant-custom.css'; */
html {
  scroll-behavior: smooth;
}

body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* Custom theme */

.ant-layout {
  background-color: #efefef;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #c9246b;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: unset;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon {
  background-color: transparent;
}
.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0 10px 10px 0;
}
.ant-input:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #c9264b;
  box-shadow: 0 0 0 2px rgb(201 38 75 / 20%);
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgb(201 38 75 / 20%);
}

.ant-menu-item {
  color: #b1b1b1;
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 16px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  font-size: 15px;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #c9264b;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: transparent;
}

.ant-menu-item {
  height: 50px !important;
  line-height: 24px !important;
  white-space: unset !important;
  list-style-position: inside;
  list-style-type: disc;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 15px !important;
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-item {
  height: 45px !important;
  line-height: 20px !important;
  font-size: 14px;
  white-space: unset !important;
}

.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 8px;
  margin-bottom: 8px;
}

#menu-sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #c9264b12;
  color: #c9246b;
  padding-left: 29px !important;
}

#menu-sidebar .ant-menu-item {
  padding-left: 32px !important;
}

#product-selected-list li:first-child,
#product-selected-list li:last-child {
  margin-left: 0px;
  margin-right: 0px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #c9264b12;
}

.ant-menu-item-selected {
  color: #c9264b;
  border-left: 3px solid #c9264b;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: unset;
}

.ant-menu-horizontal {
  border: unset;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: #c9264b;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
  /* border-bottom: 2px solid #C9264B; */
  border-bottom: unset;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: #c9264b;
  border-left: unset;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  /* border-bottom: 2px solid #C9264B; */
  border-bottom: unset;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
  color: #c9264b;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after {
  /* border-bottom: 2px solid #C9264B; */
  border-bottom: unset;
}

.ant-card {
  border-radius: 10px;
}
.ant-card > .ant-card-head {
  border: unset;
}
.ant-card > .ant-card-body {
  padding: 15px;
}
.ant-btn {
  border-radius: 8px;
}
.ant-btn:hover {
  color: #c9264b;
  border-color: #c9264b;
}
.ant-btn .ant-btn-text,
.ant-dropdown-trigger:hover {
  color: #c9264b;
  border: unset;
}
.ant-btn-link {
  color: #c9264b;
}
.ant-btn-link > span {
  text-decoration: underline;
}
.ant-btn-link:hover {
  color: unset;
  border-color: transparent;
}
.ant-btn:active {
  color: #c9264b;
}
.ant-btn:focus {
  color: #c9264b;
  border-color: #c9264b;
}
.ant-btn .ant-btn-text,
.ant-dropdown-trigger:focus {
  border: unset;
}
.ant-btn-primary {
  background-color: #c9264b;
  border-color: #9f1d3c;
}
.ant-btn-primary:hover {
  background-color: #9f1d3c;
  border-color: #9f1d3c;
  color: white;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #9f1d3c;
  border-color: #9f1d3c;
  color: white;
}

.ant-input:focus {
  border-color: #c9264b;
  box-shadow: 0 0 0 2px rgb(201 38 75 / 20%);
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #c9264b;
}

.ant-pagination-item-active {
  border-color: #c9264b;
}
.ant-pagination-item-active a {
  color: #c9264b;
}
.ant-pagination-item-active:hover a {
  color: #c9264b;
}
.ant-pagination-item:hover a {
  color: #c9264b;
}
.ant-pagination-item:hover {
  border-color: #c9264b;
}
.ant-pagination-prev:hover .ant-pagination-item-link:hover,
.ant-pagination-next:hover .ant-pagination-item-link:hover {
  border-color: #c9264b;
  color: #c9264b;
}

.ant-modal > .ant-modal-content {
  border-radius: 20px;
}
.ant-modal > .ant-modal-content > .ant-modal-header {
  border-bottom: unset;
  border-radius: 20px 20px 0 0;
}
.ant-modal > .ant-modal-content > .ant-modal-footer {
  padding: 20px 16px;
  border-top: unset;
  border-radius: 0 0 20px 20px;
}
.ant-modal > .ant-modal-content > .ant-modal-footer > button {
  border-radius: 5px;
}

.ant-select:hover {
  border-color: #c9264b;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #c9264b;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #c9264b;
  box-shadow: 0 0 0 2px rgb(201 38 75 / 20%);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffe6e6;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #c9264b;
}

.ant-dropdown
  > .ant-dropdown-menu
  > .ant-dropdown-menu-item
  > .ant-dropdown-menu-title-content
  > button {
  border: unset;
  box-shadow: unset;
  background-color: unset;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--color-primary);
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--color-primary);
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-primary);
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--color-primary);
}
.ant-switch-checked {
  background: var(--color-primary);
}

.empty-content {
  width: 30%;
  min-height: max-content;
  text-align: center;
  margin: auto;
  /* margin-top: 100px; */
}

.empty-content > .content-empty {
  margin-top: 30px;
  margin-bottom: 30px;
}

.ant-row
  > .ant-col
  > .ant-form-item-control-input
  .ant-form-item-control-input-content
  .form-action-button {
  text-align: right;
}
.ant-row
  > .ant-col
  > .ant-form-item-control-input
  .ant-form-item-control-input-content
  .form-action-button
  button {
  margin-left: 10px;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #c9264b;
}
a.ant-typography,
.ant-typography a {
  color: #c9264b;
}
a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #ab2141;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ab2141;
}
.ant-tabs-tab:hover {
  color: #ab2141;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #fc9ab0;
}

.ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
}
.ant-modal-confirm-btns > .ant-btn {
  margin-left: 10px;
}
.ant-popover-buttons {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: row-reverse;
}
.ant-popover .ant-popover-content .ant-popover-inner-content .ant-btn {
  margin-left: 10px;
}
.ant-dropdown-menu {
  border-radius: 5px !important;
  padding: 10px;
}

.row-image-question {
  margin: 20px 0;
}
.ant-image .question-image {
  height: 225px;
  width: 300px;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  background-color: #bdbdbd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* .ant-image .ant-image-mask {
  border-radius: 10px;
} */
