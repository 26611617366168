#DetailActivity .header-activity {
  background-color: var(--color-background-content);
  border-radius: 10px;
  padding: 20px 30px;
  background-image: url('https://bolehbelajar.com/wp-content/uploads/2022/03/baner-home.png');
  background-color: var(--color-primary);
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  display: grid;
  align-content: space-between;
}

#DetailActivity .header-activity > .action-header {
  margin-bottom: 20px;
  text-align: right;
}
#DetailActivity .header-activity > .action-header > div > button {
  color: white;
  font-weight: bold;
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
}

#DetailActivity .header-activity > .information-header {
  padding: 30px;
  background-color: #ffffffa3;
  border-radius: 10px;
}
#DetailActivity .header-activity > .information-header > .ant-image {
  padding: 30px;
  background-color: var(--color-primary);
  border-radius: 10px;
}
#DetailActivity .header-activity > .information-header > .content-header {
  width: 60%;
}
#DetailActivity .header-activity > .information-header > .content-header .ant-typography {
  /* color: white; */
}
#DetailActivity .header-activity > .information-header > .content-header .action-setup-activity {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
#DetailActivity .header-activity > .information-header > .content-header .action-setup-activity .action {
  height: 50px;
  padding: 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: var(--color-lighygray);
}
#DetailActivity .header-activity > .information-header > .content-header .action-setup-activity .ant-typography {
  color: black;
}
#DetailActivity .header-activity > .information-header > .content-header .action-setup-activity .action .icon-instructure {
  background-color: #fbf0f2;
  padding: 5px;
  border: 1px solid var(--color-white);
  color: #C9264B;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  text-align: center;
  position: relative;
  margin-right: -10px;
}

#DetailActivity .content-detail {
  margin: 20px 0;
  padding: 10px 0 10px 0;
  background-color: var(--color-background-content);
  border-radius: 10px;
}
#DetailActivity .content-detail > .action-content {
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#DetailActivity .content-detail > .action-content > button {
  margin-left: 10px;
  border: unset;
  box-shadow: unset;
  background-color: unset;
}

#DetailActivity .empty-content {
  width: 40%;
  min-height: max-content;
  text-align: center;
  margin: auto;
  margin-top: 100px;
}

#DetailActivity .ant-btn-icon-only.ant-btn-lg:nth-child(3) {
  
}

#DetailActivity .ant-btn-icon-only.ant-btn-lg:nth-child(3):hover {
  background-color: #D92626;
}


#DetailActivity .ant-btn-icon-only.ant-btn-lg:nth-child(2) {
  border: 1px solid #333232;
}

#DetailActivity .ant-btn-icon-only.ant-btn-lg:nth-child(2):hover {
  border: 1px solid #747474;
  color: #747474;
}
